var mapStyle = [
    {
        "featureType": "all",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#444444"
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.neighborhood",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#bdbab4"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "color": "#ff0000"
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#a8a9a8"
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            },
            {
                "color": "#ece9e5"
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ece9e5"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#bdbab4"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    }
];
  
if ($("#map").length > 0) {
    google.maps.event.addDomListener(window, "load", initialize_map);
  }
  
  function initialize_map() {
    var coords_dynamic = jQuery("#map").attr("data-coords");
    var locations;
    locations = coords_dynamic.split(" , ");
    var locations_array = [];
    locations.forEach(function(location) {
        var location_array = location.split(",");
        locations_array.push(location_array);
    });

    var myLatlng = new google.maps.LatLng(parseFloat(locations_array[0][0]), parseFloat(locations_array[0][1]));

    var mapOptions = {
        zoom: 10,
        gestureHandling: "cooperative",
        center: myLatlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: mapStyle,
        scrollwheel: false,
        mapTypeControl: false,
        fullscreenControl: false,
    };

    var map = new google.maps.Map(document.getElementById("map"), mapOptions);
    var bounds = new google.maps.LatLngBounds();
    var iconsource = "https://www.aishahotelcollection.gr/wp-content/themes/aisha-collection/assets/images/map_pin.svg";
    
    // Resize pin on small screens
    var pinSize = (window.innerWidth < 768) ? new google.maps.Size(40, 40) : new google.maps.Size(60, 60);

    locations_array.forEach(function(location) {
        var latLng = new google.maps.LatLng(parseFloat(location[0]), parseFloat(location[1]));

        var marker = new google.maps.Marker({
            position: latLng,
            icon: {
                url: iconsource,
                scaledSize: pinSize // Scale the pin size
            },
            map: map
        });

        marker.setAnimation(google.maps.Animation.BOUNCE);
        setTimeout(function () {
            marker.setAnimation(null);
        }, 1000);

        bounds.extend(latLng);
    });

    map.fitBounds(bounds);

    var styledMap = new google.maps.StyledMapType(mapStyle, {
        name: "Styled Map",
    });
    map.mapTypes.set("mapStyle", styledMap);
    map.setMapTypeId("mapStyle");

    google.maps.event.addListenerOnce(map, 'bounds_changed', function() {
        setTimeout(function() {
            map.setZoom(map.getZoom() - 1);
        }, 1);
    });
}

  