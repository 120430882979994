/*scroll header*/
$(window).scroll(function() {
  if ($(document).scrollTop() > 40) {
      $('.header_wrapper').addClass('scrolled');
  }
  else{
    $('.header_wrapper').removeClass('scrolled');
  }
});

/*Open Menu*/
$(".menu_button").on('click', function() {

      if($(this).hasClass('is-active')){
          //$('#changeimage').css('opacity','0');
          $("body").css('overflow-y','scroll');
          $(this).removeClass('is-active');
          $('.menu_button').removeClass('is-active');
          $('.header_menu_controls .menu_button').addClass('is-active');
          $('.headermenu').removeClass('is-active');
          $('.header_wrapper').removeClass('openmenu');
          //$('.menu li').removeClass('aos-animate');
        }
        else{
        //  $('#changeimage').css('opacity','1');
          $(this).addClass('is-active');
          $('.header_menu_controls .menu_button').addClass('is-active');
          jQuery("body").css('overflow-y','hidden');
          $('.headermenu').addClass('is-active');
          $('.header_wrapper').addClass('openmenu');
      }


});

if (document.querySelector('.page-template-tmpl_home')) {
  document.body.classList.add('noscroll');
  document.querySelector('#landing.home').classList.add('aos-animate');
  setTimeout(() => {
    document.body.classList.remove('noscroll');
  }, 5000);
}


import Swiper, { Navigation, EffectFade } from 'swiper';
//document.addEventListener("DOMContentLoaded", function() {
const aboutSwiper = new Swiper(".about-swiper", {
  slidesPerView: 1,
  draggable: true,
  grabCursor: true,
  speed: 800,
  spaceBetween: 100,
  navigation: {
    prevEl: '.about-swiper-button-prev',
    nextEl: '.about-swiper-button-next',
  },
});

// Footer Weather
document.addEventListener('DOMContentLoaded', () => {
  const tempDiv = document.getElementById('temp');
  //Chania
  const latitude = "35.5112"; 
  const longitude = "24.0292";

  const apiUrl = `https://api.open-meteo.com/v1/forecast?latitude=${latitude}&longitude=${longitude}&current=temperature_2m`;

  fetch(apiUrl)
    .then(response => response.json())
    .then(data => {
      const { current } = data;
      const temperature = current.temperature_2m;
      const weatherContent = `${Math.round(temperature)}&deg`;

      tempDiv.innerHTML = weatherContent;
    })
    .catch(error => {
      console.log('Error fetching weather data:', error);
    });
});

// Homepage Collection Swiper
const collectionSwiper = new Swiper(".collection_swiper", {
  slidesPerView: 1,
  loop: true,
  navigation: {
    nextEl: '.collection-swiper-next',
  },
});
// check if current page has collectionSwiper
if (document.querySelector('.collection_swiper') != null) {
  collectionSwiper.on('slideChange', function () {
    document.querySelector('.current-index').textContent = (collectionSwiper.activeIndex - 1) % (collectionSwiper.slides.length - 2) + 1;
  });
// Update swiper amount and title
  const collectionTitle = document.querySelector('.collection-title');
  const collectionText = document.querySelector('.collection-text');

  document.querySelector('.swiper-amount').textContent = collectionSwiper.slides.length - 2;

  collectionTitle.textContent = collectionSwiper.slides[collectionSwiper.activeIndex].querySelector('.swiper-slide-title').textContent;
  collectionText.textContent = collectionSwiper.slides[collectionSwiper.activeIndex].querySelector('.swiper-slide-text').textContent;
  
  // Update swiper details
  collectionSwiper.on('slideChange', function () {
    var currentSlide = collectionSwiper.slides[collectionSwiper.activeIndex];
    gsap.to(collectionTitle, {duration: 0.2, opacity: 0, onComplete: function() {
      collectionTitle.textContent = currentSlide.querySelector('.swiper-slide-title').textContent;
      gsap.to(collectionTitle, {duration: 0.2, opacity: 1});
    }});
    gsap.to(collectionText, {duration: 0.2, opacity: 0, onComplete: function() {
      collectionText.textContent = currentSlide.querySelector('.swiper-slide-text').textContent;
      gsap.to(collectionText, {duration: 0.2, opacity: 1});
    }});
  });

}

if (window.innerWidth > 768) {
  let clientX = -100;
  let clientY = -100;
  let lastX = -100;
  let lastY = -100;
  const cursor = document.querySelector('.cursor')

  // Show/hide the cursor when it is over the swipers
  if (cursor) {
    const homeOffers = document.querySelector('#home-offers');
    if (homeOffers) {
      const offersImages = document.querySelector('.offers-images-wrapper');
    
      homeOffers.addEventListener('mouseenter', () => {
        cursor.classList.add('visible')
      })

      homeOffers.addEventListener('mouseleave', () => {
        cursor.classList.remove('visible')
      })

      offersImages.addEventListener('mouseenter', () => {
        cursor.classList.add('offers-cursor')
      })
      
      offersImages.addEventListener('mouseleave', () => {
        cursor.classList.remove('offers-cursor')
      }) 
    }

    const philosophyContent = document.querySelector('.philosophy-content');
    if (philosophyContent) {
      const philosophyGallery = document.querySelector('.philosophy-gallery');

      philosophyContent.addEventListener('mouseenter', () => {
        cursor.classList.add('visible')
      })

      philosophyContent.addEventListener('mouseleave', () => {
        cursor.classList.remove('visible')
      })

      philosophyGallery.addEventListener('mouseenter', () => {
        cursor.classList.add('gallery-cursor')
      })
      
      philosophyGallery.addEventListener('mouseleave', () => {
        cursor.classList.remove('gallery-cursor')
      }) 
    }
  }

  // function for linear interpolation of values
  const lerp = (a, b, n) => {
    return (1 - n) * a + n * b;
  };

  const initCursor = () => {
    if (!cursor) return

    // add listener to track the current mouse position
  document.addEventListener('mousemove', e => {
      clientX = e.clientX;
      clientY = e.clientY;
    });

    // transform the cursor to the current mouse position
    // use requestAnimationFrame() for smooth performance
    const render = () => {
      // lesser delta, greater the delay that the custom cursor follows the real cursor
      const delta = 0.1;
      lastX = lerp(lastX, clientX, delta);
      lastY = lerp(lastY, clientY, delta);

      cursor.style.transform = `translate(${lastX}px, ${lastY}px)`;

      requestAnimationFrame(render);
    };

    requestAnimationFrame(render);
  };

  initCursor();

}


// Homepage experience Swiper
const experience_swi = new Swiper(".experience-swiper", {
  loop: true,
  spaceBetween: 60,
  grabCursor: true,
  slidesPerView: 2.7,
  speed: 1000,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false
  },

  breakpoints: {
    0: {
      slidesPerView: 1,
    },

    768: {
      slidesPerView: 1.5,
    },

    1025: {
      slidesPerView: 2,
    },

    1280: {
      slidesPerView: 2.7,
    },
  }
});


//Philosophy Gallery
const philosophySwiper = new Swiper(".philosophy-swiper", {
  slidesPerView: 'auto',
  loop: true,
  grabCursor: true,
  spaceBetween: 30,
  breakpoints: {
    0: {
      spaceBetween: 30,
    },

    768: {
      spaceBetween: 60,
    },
  }
});

document.addEventListener('DOMContentLoaded', function() {
  const filters = document.querySelectorAll('.offers-filters button');
  if (filters) {
    const offers = document.querySelectorAll('.offer-wrapper');

    filters.forEach(filter => {
      filter.addEventListener('click', function() {
        const filterValue = this.dataset.filter;

        filters.forEach(filter => filter.classList.remove('active'));
        this.classList.add('active');

        offers.forEach(offer => {
            if (offer.dataset.filter === filterValue || filterValue === 'all') {
                offer.classList.add('active');
            } else {
                offer.classList.remove('active');
            }
        });
      });
    });
  };
});